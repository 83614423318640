import React from "react";
import NavBar from "./NavBar";
import SideBar from "./SideBar";

const HeaderAndsidebar = () => {
  return (
    <>
      <NavBar />
      <SideBar />
    </>
  );
};

export default HeaderAndsidebar;
