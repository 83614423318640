import React from "react";
import Label from "../../components/headerAndSidebar/Label";

const Users = () => {
  return (
    <div>
      <Label>Users</Label>
    </div>
  );
};

export default Users;
