import { FaSignInAlt, FaSignOutAlt, FaUser } from "react-icons/fa";
import { Link, useNavigate, useLocation } from "react-router-dom";

import logo from "../assets/ssct-logo.jpg";
import { useLogoutMutation } from "../features/authUser/usersApiSlice";
import { logout } from "../features/authUser/authSlice";
import { useEffect } from "react";
import { ordersFetched } from "../features/orders/ordersSlice";
import { foodsFetched } from "../features/foods/foodsSlice";
import { cartsFetched } from "../features/carts/cartsSlice";
import { useSelector, useDispatch } from "react-redux";

import { useGetCartMutation } from "../features/carts/cartsApiSlice";
const Header = () => {
  const navigage = useNavigate();
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.auth);

  const [logoutApiCall, { isLoading }] = useLogoutMutation();
  const location = useLocation();
  const logoutHanler = async () => {
    try {
      await logoutApiCall().unwrap();
      dispatch(logout());
      dispatch(ordersFetched([]));
      dispatch(foodsFetched([]));
      dispatch(cartsFetched([]));
      navigage("/");
    } catch (error) {
      console.log(error);
    }
  };
  const { carts } = useSelector((state) => state.carts);
  const [getCart, { isLoading: getCartLoading }] = useGetCartMutation();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getCart().unwrap();

        dispatch(cartsFetched(data));
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const currentPathname = location.pathname;
  const currentSearch = location.search;
  const currentHash = location.hash;

  // You can also access the entire URL string using location.pathname + location.search + location.hash
  const currentUrl = `${location.pathname}${location.search}${location.hash}`;

  return (
    <>
      {/* {isLoading && <LoadingSpinner />} */}
      <header className="drop-shadow-lg bg-white border-b-2 border-orange-200 flex flex-col sm:flex-row justify-between items-center p-2">
        <div className="text-center sm:text-left">
          <Link className=" ">
            <div className="flex flex-col sm:flex-row items-center gap-2">
              <img src={logo} className=" w-24" />
              <div className="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap dark:text-white">
                SNSU School Canteen Management
              </div>
            </div>
          </Link>
        </div>
        <ul className="flex  sm:text-base    gap-4 mx-3 items-center font-semibold">
          {userInfo ? (
            <>
              {userInfo.data?.user.roles[0] === "user" && (
                <>
                  <li>
                    <Link
                      to={"/"}
                      className={` ${
                        currentUrl === "/" ? "border-b-4 border-orange-600" : ""
                      }`}
                    >
                      {" "}
                      Foods
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/cart"}
                      className={`flex gap-1 ${
                        currentUrl === "/cart"
                          ? "border-b-4 border-orange-600"
                          : ""
                      }`}
                    >
                      <div>Cart </div>
                      <div className="text-center text-[12px] bg-orange-500 text-white rounded-full w-[20px]  ">
                        {carts.length}{" "}
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/order"}
                      className={` ${
                        currentUrl === "/order"
                          ? "border-b-4 border-orange-600"
                          : ""
                      }`}
                    >
                      Orders
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/profile-user"}
                      className={` ${
                        currentUrl === "/profile-user"
                          ? "border-b-4 border-orange-600"
                          : ""
                      }`}
                    >
                      Profile
                    </Link>
                  </li>
                </>
              )}
              <li>
                <button
                  className="flex items-center gap-2 bg-dark px-2 py-1 text-white rounded"
                  onClick={logoutHanler}
                >
                  <FaSignOutAlt />
                  Logout
                </button>
              </li>
            </>
          ) : (
            <>
              <li>
                <Link
                  to="/"
                  className="flex items-center p-1  gap-1 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                >
                  <svg
                    className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 18 16"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M1 8h11m0 0L8 4m4 4-4 4m4-11h3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-3"
                    />
                  </svg>
                  <span className="flex-1 whitespace-nowrap   ">Sign In</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/register"
                  className="flex items-center   p-1  gap-1 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                >
                  <svg
                    className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.96 2.96 0 0 0 .13 5H5Z" />
                    <path d="M6.737 11.061a2.961 2.961 0 0 1 .81-1.515l6.117-6.116A4.839 4.839 0 0 1 16 2.141V2a1.97 1.97 0 0 0-1.933-2H7v5a2 2 0 0 1-2 2H0v11a1.969 1.969 0 0 0 1.933 2h12.134A1.97 1.97 0 0 0 16 18v-3.093l-1.546 1.546c-.413.413-.94.695-1.513.81l-3.4.679a2.947 2.947 0 0 1-1.85-.227 2.96 2.96 0 0 1-1.635-3.257l.681-3.397Z" />
                    <path d="M8.961 16a.93.93 0 0 0 .189-.019l3.4-.679a.961.961 0 0 0 .49-.263l6.118-6.117a2.884 2.884 0 0 0-4.079-4.078l-6.117 6.117a.96.96 0 0 0-.263.491l-.679 3.4A.961.961 0 0 0 8.961 16Zm7.477-9.8a.958.958 0 0 1 .68-.281.961.961 0 0 1 .682 1.644l-.315.315-1.36-1.36.313-.318Zm-5.911 5.911 4.236-4.236 1.359 1.359-4.236 4.237-1.7.339.341-1.699Z" />
                  </svg>
                  <span className="flex-1   whitespace-nowrap ">Sign Up</span>
                </Link>
              </li>
              <li>
                <Link to={"/admin"}>Admin</Link>
              </li>
            </>
          )}
        </ul>
      </header>
    </>
  );
};

export default Header;
