import React from "react";
import Label from "../../components/headerAndSidebar/Label";

const Tasks = () => {
  return (
    <div>
      <Label>Tasks</Label>
    </div>
  );
};

export default Tasks;
